$(function() {
    $('.js-intro-picture').hide();
    $('.js-intro-picture:first').show();
    $('.js-intro-picture div:first').addClass('active');
    $('.js-intro-tab a:first').addClass('active');
    $('.js-intro-tab a').click(function(event) {
        $('.js-intro-tab a').removeClass('active');
        $(this).addClass('active');
        $('.js-intro-picture').hide();
        let selectTab = $(this).attr("data-href");
        $(selectTab).fadeIn();
    });
})
