$(function() {
    $('.award__list').hide();
    $('.award__list:first').show();
    $('.award__tab').click(function(event) {
        $('.award__tab').removeClass('active');
        $(this).addClass('active');
        $('.award__list').hide();
        let selectTab = $(this).attr("data-href");
        $(selectTab).fadeIn("200", function () {
            $('.js-slider-award').slick("reinit");
        });
    });

    const tabAwardSlider = $(".js-slider-award-tab");

    if(!!tabAwardSlider.length) {
        tabAwardSlider.slick({
            centerMode: false,
            infinite: true,
            responsive: [
                {
                    breakpoint: 100000,
                    settings: "unslick"
                },
                {
                    breakpoint: 551,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
            ]
        })
    }

    const awardSlider = $(".js-slider-award");
    const arrows = awardSlider.data("arrows").split(",");

    if(!!awardSlider.length) {
        awardSlider.slick({
            autoplay: false,
            autoplaySpeed: 2000,
            centerMode: false,
            infinite: true,
            responsive: [
                {
                    breakpoint: 100000,
                    settings: "unslick"
                },
                {
                    breakpoint: 551,
                    settings: {
                        arrows: true,
                        prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
                        nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
                    }
                },
            ]
        })
    }
})
