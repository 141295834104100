$(() => {
    const serviceSliderNav = $(".js-slider-service-nav");
    const serviceSliderSingle = $(".js-slider-service-single");
    const arrows = serviceSliderNav.data("arrows").split(",");

    serviceSliderSingle.slick({
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        asNavFor: serviceSliderNav,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    adaptiveHeight: true,
                }
            },
        ]
    });

    serviceSliderNav.slick({
        centerMode: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: serviceSliderSingle,
        arrows: true,
        prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
        nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 4,
                }
            },
        ]
    });
})
