import "%modules%/header/header";
import "%modules%/footer/footer";

import "%modules%/form/form";

import "%modules%/intro/intro";
import "%modules%/service/service";
import "%modules%/number/number";
import "%modules%/award/award";
import "%modules%/worth/worth";
