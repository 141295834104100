$(function() {
    const numberSlider = $(".js-slider-number");
    const arrows = numberSlider.data("arrows").split(",");

    if(!!numberSlider.length) {
        numberSlider.slick({
            centerMode: false,
            infinite: true,
            arrows: true,
            prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
        })
    }
})
